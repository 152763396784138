<template>
	<v-card flat style="width: 100%">
		<v-app-bar flat color="rgba(0, 0, 0, 0)" class="pl-0">
			<v-card-title class="pl-0" style="padding-left: -16px">
				Administrators
			</v-card-title>

			<v-spacer></v-spacer>

			<Roundup-Button color="white" @click="addUser">
				Invite Administrator
			</Roundup-Button>
		</v-app-bar>
		<div>
			<div
				v-if="error"
				class="alert alert-error"
				:style="{ marginBottom: '25px' }"
			>
				{{ error }}
			</div>
			<div
				v-if="success"
				class="alert alert-info"
				:style="{ marginBottom: '25px' }"
			>
				{{ success }}
			</div>

			<el-table slot="table" :data="items" style="width:100%">
				<el-table-column
					prop="first_name"
					label="First Name"
					width="180"
				>
				</el-table-column>
				<el-table-column prop="last_name" label="Last Name" width="180">
				</el-table-column>
				<el-table-column prop="email" label="Email"> </el-table-column>
				<el-table-column prop="invite_code" label="State">
					<template slot-scope="scope">
						{{
							isEmpty(scope.row.invite_code)
								? 'Active'
								: 'Pending'
						}}
					</template>
				</el-table-column>
				<el-table-column label="Actions">
					<template slot-scope="scope">
						<el-button
							size="mini"
							@click="navigateToUserDetail(scope.row)"
							>Edit</el-button
						>
						<el-button
							v-if="!isEmpty(scope.row.invite_code)"
							size="mini"
							:disabled="isSaving"
							@click="onResendInvite(scope.row)"
							>Resend Invite</el-button
						>
						<ui-loader :loading="isSaving" />
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				v-if="items.length"
				@current-change="pageChange"
				:current-page="page"
				:page-size="perPage"
				layout="prev, pager, next, total"
				:total="total"
			>
			</el-pagination>

			<modal v-model="showUser" size="small">
				<user-edit-view></user-edit-view>
			</modal>
		</div>
	</v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { get, isEmpty } from '@/utilities'
import table from '@/mixins/table'
import UiButton from '../../ui/Button'
import UiLoader from '../../ui/Loader'
import Screen from '../../ui/Screen'
import Modal from '../../ui/Modal'
import UserEditView from './UserEditView'

export default {
	name: 'user-list-view',
	components: { UiButton, Screen, Modal, UserEditView, UiLoader },
	mixins: [table],
	computed: {
		...mapState({
			items: ({ userList }) => userList.items,
			total: ({ userList }) => userList.total
		})
	},
	data() {
		return {
			showUser: false,
			error: null,
			isSaving: false,
			success: null,
			inviteCode: null
		}
	},
	methods: {
		...mapActions(['getUsers', 'resendInvite']),
		navigateToDonorDetail(donor) {
			this.$router.push({
				name: 'donors.detail',
				params: { donorId: donor.id }
			})
		},
		paramsChanged(params) {
			this.getUsers(params)
		},
		addUser() {
			this.$store.commit('USER_DETAIL', { id: null })
			this.showUser = true
		},
		navigateToUserDetail(user) {
			this.$store.commit('USER_DETAIL', user)
			this.showUser = true
		},
		get,
		isEmpty,
		onResendInvite(admin) {
			this.isSaving = true
			this.inviteCode = admin.invite_code
			this.resendInvite(admin)
				.then(() => {
					this.isSaving = false
					this.success = 'Your invite has been resent.'
					setTimeout(() => {
						this.success = null
					}, 2000)
				})
				.catch(({ message }) => {
					this.isSaving = false
					this.error = message
				})
			return false
		}
	},
	mounted() {
		this.getUsers(this.getParameters())
	}
}
</script>

<style lang="scss">
@import url('//unpkg.com/element-ui@2.13.2/lib/theme-chalk/index.css');

@import '../../../styles/variables';

.v-toolbar__content {
	padding-left: 0px !important;
}

.el-table__header-wrapper {
	position: relative;
}
.el-table--enable-row-hover {
	.el-table__body {
		tr {
			td {
				transition: none;
				cursor: pointer;
			}
			&:hover {
				border-radius: 5px;
				overflow: hidden;
				td {
					&:first-child {
						border-top-left-radius: 10px;
						border-bottom-left-radius: 10px;
					}
					&:last-child {
						border-top-right-radius: 10px;
						border-bottom-right-radius: 10px;
					}
				}
				& > td {
					background: $roundup-navy;
					color: $white;
				}
			}
		}
	}
}
</style>
