<template>
	<section class="user-edit-view">
		<h3 v-if="user.id">Edit Administrator</h3>
		<h3 v-else>Invite Administrator</h3>
		<div v-if="error" class="alert alert-error">{{ error }}</div>
		<form v-on:submit.prevent="submit">
			<ui-label>
				<ui-input
					v-model="user.first_name"
					placeholder="First Name"
				></ui-input>
			</ui-label>
			<ui-label>
				<ui-input
					v-model="user.last_name"
					placeholder="Last Name"
				></ui-input>
			</ui-label>
			<ui-label>
				<ui-input v-model="user.email" placeholder="Email"></ui-input>
			</ui-label>
			<ui-label>
				<ui-input
					type="number"
					v-model="user.phone"
					placeholder="Cell/ Direct Phone"
				></ui-input>
			</ui-label>
			<ui-label>
				<ui-input v-model="user.title" placeholder="Title"></ui-input>
			</ui-label>
			<ui-button native-type="submit" :loading="isSaving">{{
				user.id ? 'Save' : 'Invite'
			}}</ui-button>
			<ui-button
				type="danger"
				v-if="user.id && user.email !== me.email"
				@click="remove"
				style="float:right"
				>Delete</ui-button
			>
		</form>
	</section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { isEmpty } from '@/utilities'

import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'

export default {
	name: 'user-edit-view',
	inject: ['modalOptions'],
	components: { UiButton, UiLabel, UiInput },
	data() {
		return {
			user: cloneDeep(this.$store.state.userDetail.item),
			isSaving: false,
			error: null
		}
	},
	computed: {
		...mapGetters(['me'])
	},
	methods: {
		...mapActions(['updateUser', 'createUser', 'removeUser']),
		submit() {
			this.user.id ? this.update() : this.create()
		},
		create() {
			this.isSaving = true
			this.createUser(this.user)
				.then(user => {
					this.isSaving = false
					this.modalOptions.modalOpen = false
				})
				.catch(({ message }) => {
					this.isSaving = false
					this.error = message
				})

			return false
		},
		update() {
			this.isSaving = true
			this.updateUser(this.user)
				.then(() => {
					this.isSaving = false
					this.modalOptions.modalOpen = false
				})
				.catch(({ message }) => {
					this.isSaving = false
					this.error = message
				})

			return false
		},
		remove() {
			if (
				confirm(
					'Are you sure you want to remove this user? This action cannot be undone.'
				)
			) {
				this.removeUser(this.user).then(() => {
					this.modalOptions.modalOpen = false
				})
			}

			return false
		},
		isEmpty
	}
}
</script>

<style lang="scss"></style>
